.popup-overlay {
    height: 100%;
    width: 100%;
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: grid;
    align-items: center;
    justify-content: center;
    padding: 40px 0;
    background: rgba(46, 62, 81, 0.5);
    opacity: 0;
    transition: opacity ease 150ms;

    &.isScrollOverlay {
        overflow-y: auto;
    }

    &.isZeroStock {
        padding-bottom: 0;
    }

    &.isDetailOffers {
        @media screen and (max-width: 767px) {
            padding-bottom: 0;
        }
    }

    &.isFullscreen {
        padding: 0;
    }

    &.leftHanded {
        padding: 0;
    }

    &--index {
        z-index: 1001;
    }

    &.isCatalog {
        align-items: flex-start;

        @media screen and (max-width: 1279px) {
            align-items: center;
        }
    }

    &.isCart {
        padding: 0;
    }

    &--after-open {
        opacity: 1;
    }

    &--before-close {
        opacity: 0;
        transition: opacity ease 300ms;
    }

    &--offset {
        top: 135px;

        &-custom {
            top: 92px;

            @media screen and (max-width: 1279px) {
                top: 0;
            }
        }

        @media screen and (max-width: 1279px) {
            top: 0;
        }
    }
    &.isAuth {
        @media screen and (max-width: 567px) {
            padding: 0;
            align-items: end;
        }
    }
}

.popup-content {
    max-height: 100%;
    background: white;
    position: relative;
    transform: scale(0.9);
    transition: transform ease 150ms;
    border-radius: 4px;

    &.scrollInside {
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;

        scrollbar-width: thin;
        scrollbar-gutter: stable;

        &::-webkit-scrollbar {
            width: 3px;
            height: 3px;

            &-thumb {
                background-color: #728895;
            }
        }
    }

    &.isFullscreen {
        width: 100vw;
        height: 100%;
        border-radius: 0;
    }

    &.isZeroStock {
        border-radius: 20px;

        @media screen and (max-width: 767px) {
            border-radius: 20px 20px 0 0;
        }
    }

    &.isDetailOffers {
        border-radius: 20px;

        @media screen and (max-width: 767px) {
            border-radius: 20px 20px 0 0;
        }
    }

    &.isCatalog {
        height: 100%;

        @media screen and (max-width: 1279px) {
            width: 70vw;
            height: 80vh;
        }

        @media screen and (max-width: 567px) {
            width: 100vw;
            height: 100%;
            overflow-y: hidden;
        }
    }

    &.isCart {
        width: 80vw;
        height: 100%;
        overflow: hidden;

        @media screen and (min-width: 568px) {
            max-height: 80vh;
        }

        @media screen and (max-width: 567px) {
            width: 100vw;
            border-radius: 0;
        }
    }

    &.isAuth {
        @media screen and (max-width: 567px) {
            width: 100vw;
            border-top-right-radius: 20px;
            border-top-left-radius: 20px;
            border-bottom-right-radius: 0px;
            border-bottom-left-radius: 0px;
            height: 80%;
        }
    }

    &.isDelivery {
        width: 80vw;
        height: auto;
        max-height: 100%;
        max-width: 904px;

        @media screen and (max-width: 1279px) {
            width: 100vw;
        }

        @media screen and (max-width: 567px) {
            width: 100vw;
            max-height: 100%;
            border-radius: 0;
        }
    }

    &.isRefund {
        width: 80vw;
        height: 100%;
        max-height: 860px;
        max-width: 856px;

        @media screen and (max-width: 1279px) {
            width: 100vw;
        }

        @media screen and (max-width: 567px) {
            width: 100vw;
            max-height: 100%;
            border-radius: 0;
        }
    }

    &.rightHanded {
        position: fixed;
        right: 0;
        height: 100%;
        transform: translateX(100%);
        transition: transform ease 150ms;
        border-radius: 0;
    }

    &.leftHanded {
        position: fixed;
        left: 0;
        height: 100%;
        transform: translateX(-100%);
        transition: transform ease 150ms;
        border-radius: 0;
        bottom: 0;
    }

    &--after-open {
        transform: scale(1);

        &.rightHanded,
        &.leftHanded {
            transform: translateX(0);
        }
    }

    &--before-close {
        transform: scale(0.9);
        transition: transform ease 300ms;

        &.rightHanded {
            transform: translateX(100%);
        }

        &.leftHanded {
            transform: translateX(-100%);
        }
    }
}

.popup-border-wrapper {
    border-radius: 20px;
}
